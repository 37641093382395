<template>
    <transition name="fade">
            <p class="text-primary text-24 line-height-1 mb-2" v-if="orderPicklists" v-html="orderPicklists"></p>
    </transition>
</template>

<script>
    export default {
        props: {
            picklistUrl: {
                type: String,
                required: true
            },
            infoUrl: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                orderPicklists: ''
            }
        },
        mounted() {
            console.log('Old Order Picklist Mounted');

            const picklistSearch = document.querySelector( '#order_picklists' );
            picklistSearch.addEventListener( 'keyup', ( event ) => {
                const sanitizedValue = event.target.value.replace(/\s+/g, '');
                picklistSearch.value = sanitizedValue;
                const charactersNeeded = document.querySelector( '.characters-needed' );
                charactersNeeded.innerText = `${7 - sanitizedValue.length} characters left`;
                this.get_links( sanitizedValue );
            });
        },
        methods: {
            get_links( orderId ) {
                if ( orderId.length != 7 ) {
                    this.orderPicklists = '';
                    return;
                }

                const token = $('input[name="_token"]').val();
                const url = `/bfs-orderPicklists?orderId=${orderId}&_token=${token}`;
                fetch( url, {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then( res => res.json() )
                    .then( data => { 
                        var newLinks = '';
                        for (var i = 0; i < data.order_notes; i++) {
                            var picklistLinkUrl = this.picklistUrl + `?sc_id=${data['order_ID']}&note_id=${data['note_IDs'][i]}`;
                            var infoLinkUrl = this.infoUrl + `?sc_id=${data['order_ID']}&note_id=${data['note_IDs'][i]}`;
                            newLinks += `<div class="picklist-link-container"><a class="picklist-link" href="${infoLinkUrl}" target="_blank">view info <span>${data['note_IDs'][i]}</span></a></div><div class="picklist-link-container"><a class="picklist-link" href="${picklistLinkUrl}" target="_blank">view picklist <span>${data['note_IDs'][i]}</span></a></div>`;
                        }
                        this.orderPicklists = `<div class="links-container"><div class="link-label"> Order: ${data['order_ID']} has ${data['order_notes']} picklists and engraving info Links </div>${newLinks}</div>`;
                    })
                    .catch( err => { 
                        this.orderPicklists = `Unsuccessful Order: ${orderId} does not exist in the system.`;
                        console.log( err.message );
                    } );
            }
        }
    }
</script>
